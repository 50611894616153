/**
 * 3. Site Header
 * ----------------------------------------------------------------------------
 */
.site-header {
  background: $gray-darkest;
  color: #fff;
}

.site-header-inside {
  padding: 0.83333em 3vw;
}

.site-branding {
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  font-size: 1rem;
}

.site-logo,
.profile {
  -ms-flex: 0 0 auto;
  -webkit-flex: 0 0 auto;
  flex: 0 0 auto;
  margin: 0 10px 0 0;
}

.site-logo a,
.profile a {
  color: $gray-darkest;
}

.site-logo img {
  max-height: 45px;
}

.profile .avatar {
  border: 2px solid $color-accent;
  border-radius: 100%;
  display: block;
  height: 45px;
  width: 45px;
}

.site-title {
  font-family: $font-secondary;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1;
  margin: 0;
}

.site-title a {
  color: #fff;
  text-decoration: none;
}

.site-description {
  color: $gray;
  font-family: $font-primary;
  font-weight: normal;
  font-size: 0.625rem;
  letter-spacing: 0.15em;
  line-height: 1;
  margin: 0.25em 0 0;
  text-transform: uppercase;
}

#menu-toggle {
  background-color: transparent;
  color: #fff;
  height: 30px;
  margin-left: auto;
  padding: 0;
  position: relative;
  width: 30px;
  z-index: 9999;
}

.site-navigation {
  background: $gray-darkest;
  bottom: 0;
  box-sizing: border-box;
  -webkit-overflow-scrolling: touch;
  position: absolute;
  right: -100%;
  top: 0;
  -webkit-transition: right .3s, visibility 0s .3s;
  transition: right .3s, visibility 0s .3s;
  visibility: hidden;
  width: 100%;
  z-index: 9998;
}

.site-nav-wrap {
  height: 100%;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  position: relative;
}

.site-nav-inside {
  padding: 4em 3vw;
}

.menu {
  border-bottom: 1px solid $gray-dark;
  font-size: 0.77778rem;
  line-height: 1.5;
  letter-spacing: 0.15em;
  list-style: none;
  margin: 0;
  padding: 0;
  text-transform: uppercase;
}

.menu-item {
  border-top: 1px solid $gray-dark;
  padding: 0.5em 0;
}

.menu-item a {
  color: $gray-light;
  text-decoration: none;
}

.social-links {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-top: 1.66667em;
}

.social-links a {
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  color: $gray;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  margin: 0 10px 0.5em 0;
  padding: 0 3px;
  text-decoration: none;
}

.social-links svg {
  fill: currentColor;
  height: 28px;
}

a[href*="twitter.com"] svg,
a[href*="tumblr.com"] svg,
a[href*="patreon.com"] svg,
a[href^="mailto:"] svg,
a[href$="/rss/"] svg {
  height: 26x;
}

.social-links a:hover,
.menu-item a:hover,
.current-menu-item a {
  color: $color-accent;
}

